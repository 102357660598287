.page {
  width: 100%;
  height: 100%;
  background-color: #fff;
  color: #1A1B22;
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
}

.page__content {
  min-width: 320px;
  max-width: 2560px;
  width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

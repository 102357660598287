.results {
  background: #F5F6F7;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 80px;
}

.results__title {
  font-family: 'RobotoSlab', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 1.15;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-left: 104px;
}

.results__button {
  align-self: center;
  color: #1A1B22;
  padding: 20px 84px;
  background: #fff;
  margin-top: 2px;
  border-radius: 80px;
}

@media(hover: hover) and (pointer: fine) {
  .results__button:hover {
    opacity: .9;
  }
}

@media screen and (max-width: 1050px){
  .results {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .results__title {
    font-size: 30px;
    padding-left: 40px;
  }
  .results__button {
    padding: 16px 60px;
  }
}

@media screen and (max-width: 650px){
  .results {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .results__title {
    padding-left: 16px;
    padding-right: 16px;
  }
  .results__button {
    padding: 16px 84px;
  }
}

.cards {
  padding: 62px 104px;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, 400px);
  gap: 16px;
  background: #F5F6F7;
  justify-content: center;
}

@media screen and (max-width: 1050px){
  .cards {
    padding: 32px 40px;
    grid-template-columns: repeat(auto-fill, 224px);
    gap: 8px;
  }
}

@media screen and (max-width: 650px){
  .cards {
    padding: 24px 16px 16px;
    grid-template-columns: fit-content(100%);
    gap: 10px;
  }
}

.search {
  max-width: 608px;
  width: 42.2%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  color: #fff;
  padding-top: 80px;
  padding-bottom: 80px;
}

.search__title {
  font-family: 'RobotoSlab', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 60px;
  line-height: 1.07;
  margin-top: 0;
  margin-bottom: 32px;
}

.search__subtitle {
  width: 93%;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1.33;
  margin-top: 0;
  margin-bottom: 64px;
}

.search-form {
  display: flex;
  border-radius: 100px;
  position: relative;
  align-self: center;
  width: 100%;
}

.search-form__label {
  width: 90%;
  border-radius: 100px;
}

.search-form__placeholder {
  display: none;
}

.search-form__input {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.375;
  color: #1A1B22;
  padding: 21px 24px;
  border-radius: 100px;
}

.search-form__input:focus {
  background-color: rgba(255, 255, 255, .9);
}

.search-form__error {
  color: #E6E8EB;
  top: calc(100% + 4px);
  left: 55%;
  transform: translate(-50%);
  white-space: nowrap;
}

.search-form__button {
  color: #fff;
  width:27.6%;
  background: #2F71E5;
  border-radius: 100px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.search-form__button_disabled {
  background: #E6E8EB;
  color: #B6BCBF;
  cursor: default;
}

@media screen and (max-width: 1250px){
  .search-form__button {
    width: 35.7%;
  }
}

@media(hover: hover) and (pointer: fine) {
  .search-form__button:active {
    background: #2A65CC;
  }
  .search-form__button:hover {
    background: #347EFF;
  }
  .search-form__button_disabled:hover {
    background: #E6E8EB;
  }
}

@media screen and (max-width: 975px){
  .search {
    width: 58.3%;
    padding-top: 38px;
    padding-bottom: 48px;
  }
  .search::before {
    max-height: 400px;
  }
  .search__title {
    font-size: 36px;
    line-height: 1.11;
    margin-bottom: 16px;
    width: 70%;
  }
  .search__subtitle {
    margin-bottom: 48px;
  }
  .search-form__input {
    padding-top: 17px;
    padding-bottom: 17px;
  }
}

@media screen and (max-width: 650px){
  .search {
    width: 90%;
    padding-top: 30px;
    padding-bottom: 32px;
  }
  .search::before {
    max-height: 480px;
  }
  .search__title {
    width: 100%;
  }
  .search__subtitle {
    margin-bottom: 122px;
    width: 100%;
  }
  .search-form {
    display: flex;
    flex-direction: column;
    border-radius: 0;
    max-width: 400px;
  }
  .search-form__label {
    width: 100%;
    margin-bottom: 16px;
  }
  .search-form__input {
    padding: 17px 16px;
  }
  .search-form__error {
    display: none;
  }
  .search-form__button {
    width: 100%;
    position: static;
    padding: 17px 16px;
  }
}

@media screen and (max-width: 445px){
  .search::before {
    max-height: 536px;
  }
  .search::before {
    background-image: url(../../images/SearchForm-background-mobile.jpg);
    background-size: cover;
  }
}

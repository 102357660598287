.card {
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  background: #fff;
  max-width: 400px;
}

.card__link {
  text-decoration: none;
}

.card__image {
  width: 100%;
  min-height: 272px;
  height: 272px;
  vertical-align: top;
  object-fit: cover;
  object-position: top;
}

.card__info {
  padding: 20px 24px 24px;
  height: 304px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.card__date {
  font-family: 'SourceSansPro', Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1.33;
  color: #B6BCBF;
  cursor: default;
}

.card__name {
  font-family: 'RobotoSlab', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 1.15;
  color: #1A1B22;
  margin-top: 16px;
  margin-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card__description {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.375;
  color: #1A1B22;
  margin-top: 0;
  margin-bottom: 10px;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card__source {
  font-family: 'RobotoSlab', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #B6BCBF;
}

.card__button {
  position: absolute;
  top: 24px;
  right: 26px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #fff;
}

.card__button_type_save {
  background-image: url(../../images/Card-saveButton.svg);
}

.card__button_type_save-active {
  background-image: url(../../images/Card-saveButton-active.svg);
}

.card__button_type_delete {
  background-image: url(../../images/Card-deleteButton.svg);
}

.card__tip {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 10px;
  line-height: 1.2;
  position: absolute;
  top: 24px;
  right: 72px;
  padding: 14px 12px;
  background: #fff;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: .3s ease;
}

.card__keyword {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: #000;
  position: absolute;
  top: 24px;
  left: 24px;
  padding: 10px 14px;
  background: #fff;
  border-radius: 10px;
  max-width: 33%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media(hover: hover) and (pointer: fine) {
  .card__button_type_save:hover {
    background-image: url(../../images/Card-saveButton-hover.svg);
  }
  .card__button_type_save-active:hover {
    background-image: url(../../images/Card-saveButton-active.svg);
  }
  .card__button_type_delete:hover {
    background-image: url(../../images/Card-deleteButton-hover.svg);
  }
  .card__button:hover + .card__tip {
    visibility: visible;
    opacity: 1;
  }
}

@media screen and (max-width: 1050px){
  .card__image {
    min-height: 150px;
    height: 150px;
  }
  .card__info {
    padding: 16px;
    height: 270px;
  }
  .card__name {
    font-size: 22px;
    line-height: 1.09;
    margin-top: 12px;
    margin-bottom: 10px;
  }
  .card__button {
    top: 8px;
    right: 8px;
  }
  .card__tip {
    display: none;
  }
  .card__keyword {
    top: 8px;
    left: 8px;
    padding: 12px 16px;
  }
}

@media screen and (max-width: 650px){
  .card__image {
    min-height: 244px;
    height: 244px;
  }
  .card__info {
    height: 244px;
  }
  .card__description {
    margin-bottom: 8px;
  }
  .card__button {
    top: 16px;
    right: 16px;
  }
  .card__keyword {
    top: 16px;
    left: 16px;
    padding: 12px 16px;
  }
}

@media screen and (max-width: 400px){
  .card__image {
    min-height: 196px;
    height: 196px;
  }
}


.label {
  display: flex;
  flex-direction: column;
  position: relative;
}

.label__input {
  width: 100%;
  border: 0;
  outline: none;
  transition: .3s;
  box-sizing: border-box;
  caret-color: #2F71E5;
}

.label__input:disabled {
  background-color: #fff;
}

.label__input::placeholder {
  color: #B6BCBF;
}

.label__error {
  font-size: 12px;
  line-height: 1.25;
  position: absolute;
  opacity: 0;
  transition: .3s;
  z-index: 10;
}

.label__error_active {
  opacity: 1;
}

.saved-news {
  background: #F5F6F7;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 650px){
  .saved-news {
    padding-bottom: 8px;
  }
}

.popup__container {
  margin: auto;
  max-width: 430px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 34px 36px 28px;
  box-sizing: border-box;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  position: relative;
}

.popup__title {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.17;
  margin-top: 0;
  margin-bottom: 22px;
}

.popup__close-button {
  background: url(../../images/Popup-closeButton.svg), transparent;
  width: 40px;
  height: 40px;
  position: absolute;
  top: -45px;
  right: -45px;
}

.popup-form__label {
  margin-bottom: 32px;
}

.popup-form__placeholder {
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 1.25;
  color: #2F71E5;
}

.popup-form__input {
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.21;
  color: #000;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgb(0, 0, 0, 0.2);
}

.popup-form__input::placeholder {
  opacity: .2;
}

.popup-form__input:focus {
  border-bottom-color: #000;
}

.popup-form__error {
  color: #FF0000;
  top: calc(100% + 2px);
  left: 0;
  background-color: #fff;
}

.popup-form__button {
  color: #FFFFFF;
  background: #2F71E5;
  box-shadow: 0px 5px 15px rgba(14, 26, 57, 0.2);
  border-radius: 100px;
  padding: 20px 50px;
}

.popup-form__button_disabled {
  background: #E6E8EB;
  color: #B6BCBF;
  cursor: default;
}

.popup__text {
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.43;
  color: #000;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 0;
}

.popup__link-button {
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.43;
  color: #2F71E5;
  background: #fff;
  text-align: left;
}

@media(hover: hover) and (pointer: fine) {
  .popup-form__button:active {
    background: #2A65CC;
  }
  .popup-form__button:hover {
    background: #347EFF;
  }
  .popup-form__button_disabled:hover {
    background: #E6E8EB;
  }
}

@media screen and (max-width: 975px){
  .popup-form__label {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 650px){
  .popup__container {
    padding: 16px;
    position: absolute;
    top: 57px;
    left: 50%;
    transform: translate(-50%);
    height: calc(calc(100vh - calc(100vh - 100%)) - 57px);
    border-radius: 16px 16px 0 0;
  }
  .popup__title {
    margin-bottom: 16px;
  }
  .popup__close-button {
    background: url(../../images/Header-closeButton.svg), transparent;
    width: 24px;
    height: 24px;
    top: -40px;
    right: 16px;
  }
  .popup-form__label {
    margin-bottom: 28px;
  }
  .popup-form__error {
    top: 100%;
  }
}

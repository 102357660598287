.main {
  display: flex;
  flex-direction: column;
}

.main::before {
  content: '';
  background-image: url(../../images/SearchForm-background.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -20;
  max-height: 576px;
}

.lang {
  width: 45px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.lang__trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.33;
  cursor: pointer;
}

.lang__options {
  position: absolute;
  top: 100%;
  right: -20px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
  width: 70px;
}

.lang.open .lang__options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  margin-top: 10px;
}

.lang__option {
  position: relative;
  display: block;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.33;
  cursor: pointer;
  transition: 0.3s;
  padding: 5px;
  width: 70px;
}

.lang__option_default {
  display: none;
}

.lang__option.selected {
  color: #000;
  background-color: #e6e6e6;
}

.lang__arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  transition: 0.3s;
}

.open .lang__arrow {
  transform: rotate(180deg);
}

.open .lang__arrow::after {
  transform: rotate(-180deg);
}

.lang_theme_black {
  color: #1a1b22;
}

.lang_theme_black .lang__arrow {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #1a1b22;
}

@media(hover: hover) and (pointer: fine) {
  .lang__option:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

@media screen and (max-width: 975px) {
  .lang {
    margin-right: 4px;
    width: 40px;
  }
  .lang__arrow {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
  }
  .lang__options {
    width: 60px;
  }
  .lang.open .lang__options {
    margin-top: 5px;
  }
  .lang__trigger {
    font-size: 16px;
    line-height: 1.5;
  }
  .lang__option {
    font-size: 16px;
    line-height: 1.5;
    padding: 2px;
  }
}

@media screen and (max-width: 740px) {
  .lang {
    grid-area: button;
  }
  .lang_hided {
    display: none;
  }
}
